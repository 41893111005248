import React, { useState, useEffect } from "react";
import "../assets/css/animate.css";
import "../assets/css/bootstrap.min.css"; // Adjust path if needed
import "../assets/css/glightbox.min.css";
import "../assets/css/lineicons.css";
import { useNavigate } from "react-router-dom";
import "../assets/css/main.css";
import "../assets/css/tiny-slider.css"; // Your custom styles
import SignupPage from "../SignupPage/SignupPage";
import logo from "../assets/images/logo/logo2.png";
function App() {
  const [showSignup, setShowSignup] = useState(false);
  const navigate = useNavigate();

  const headings = [
    "Mums aren't the only ones with calendars"
  ];

  const [currentHeading, setCurrentHeading] = useState(headings[0]);

  const getRandomHeading = (exclude) => {
    // const filteredHeadings = headings.filter((heading) => heading !== exclude);
    const randomIndex = Math.floor(Math.random() * (headings.length));
    alert(randomIndex);
    return headings[randomIndex];
  };

  // useEffect(() => {
  //   const newHeading = getRandomHeading(currentHeading);
  //   // setCurrentHeading(newHeading);
  // }, []);
  const handleLogin = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.phone) {
      navigate("/dashboard"); // Redirect to /dashboard if user details are found
    } else {
      setShowSignup(true); // Show SignupPage component if user details are not found
    }
  };

  return (
    <>
      {showSignup ? (
        <SignupPage />
      ) : (
        <div>
          <header className="header navbar-area">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="nav-inner">
                    {/* Start Navbar */}
                    <nav className="navbar navbar-expand-lg">
                      <a className="navbar-brand" href="/">
                        <img
                          src={require("../assets/images/logo/logo2.png")}
                          alt="Logo"
                        />
                      </a>
                      <button
                        className="navbar-toggler mobile-menu-btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="toggler-icon"></span>
                        <span className="toggler-icon"></span>
                        <span className="toggler-icon"></span>
                      </button>
                      <div
                        className="collapse navbar-collapse sub-menu-bar"
                        id="navbarSupportedContent"
                      >
                        <ul id="nav" className="navbar-nav ms-auto">
                          <li className="nav-item">
                            <a
                              href="#home"
                              className="page-scroll active"
                              aria-label="Toggle navigation"
                            >
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href="#features"
                              className="page-scroll"
                              aria-label="Toggle navigation"
                            >
                              Features
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href="javascript:void(0)"
                              aria-label="Toggle navigation"
                            >
                              How
                            </a>
                          </li>

                          <li className="nav-item">
                            <a
                              href="javascript:void(0)"
                              aria-label="Toggle navigation"
                            >
                              Why
                            </a>
                          </li>

                          <li className="nav-item">
                            <a
                              href="javascript:void(0)"
                              aria-label="Toggle navigation"
                            >
                              Contact
                            </a>
                          </li>
                          {/* Mobile-only button */}
                          <li className="nav-item d-lg-none">
                            <div>
                              <button
                                className="btn btn-alt"
                                style={{
                                  backgroundColor:
                                    "#3ab4d9" /* Green background for the button */,
                                  color: "white" /* White text */,
                                  border: "none" /* Remove default border */,
                                  borderRadius:
                                    "5px" /* Rounded corners for the button */,
                                  padding:
                                    "10px 20px" /* Padding inside the button */,
                                  fontSize: "16px" /* Font size */,
                                  cursor:
                                    "pointer" /* Pointer cursor on hover */,
                                  transition:
                                    "background-color 0.3s ease, color 0.3s ease" /* Smooth transition */,
                                  "margin-left": "10px"
                                }}
                                onClick={handleLogin}
                              >
                                Join
                              </button>
                            </div>
                          </li>
                        </ul>
                        {/* Join button visible on all screens */}
                        <div className="button add-list-button mt-3 mt-lg-0">
                          <button
                            href="javascript:void(0)"
                            className="btn"
                            onClick={handleLogin}
                          >
                            Join
                          </button>
                        </div>
                      </div>
                      {/* End navbar collapse */}
                    </nav>

                    {/* End Navbar */}
                  </div>
                </div>
              </div>
              {/* row */}
            </div>
            {/* container */}
          </header>

          {/* End Header Area */}

          {/* Start Hero Area */}
          <section id="home" className="hero-area">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5 col-md-12 col-12">
                  <div className="hero-content">
                    <h1 className="wow fadeInLeft" data-wow-delay=".4s">
                    Mums aren't the only ones with calendars
                    </h1>
                    <p className="wow fadeInLeft" data-wow-delay=".6s">
                      Sign up to manage your kids over the holidays.
                    </p>
                    <div className="button wow fadeInLeft" data-wow-delay=".8s">
                      <a href="#features" className="btn btn-alt">
                        Learn More
                      </a>
                      <a
                        href="javascript:void(0)"
                        className="btn"
                        onClick={handleLogin}
                      >
                        Join
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-12">
                  <div
                    className="hero-image wow fadeInRight"
                    data-wow-delay=".4s"
                  >
                    <img
                      src={require("../assets/images/hero/phone.png")}
                      alt="#"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Hero Area */}

          {/* Start Features Area */}
          <section id="features" className="features section">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="section-title">
                    <h2
                      className="wow fadeInUp"
                      style={{ "text-transform": "none" }}
                    >
                      Effortless playdates, real-life moments
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  {/* Start Single Feature */}
                  <div
                    className="single-feature wow fadeInUp"
                    data-wow-delay=".2s"
                  >
                    <i className="lni lni-lock"></i>
                    <h3>
                      Bank Grade <b>Security</b>
                    </h3>
                    <p>
                      Built by people with experience at world's leading tech
                      companies
                    </p>
                  </div>
                  {/* End Single Feature */}
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  {/* Start Single Feature */}
                  <div
                    className="single-feature wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <i className="lni lni-cool"></i>
                    <h3>Not a Social Network</h3>
                    <p>
                      Only connect with people you know in real life
                      <br />
                      <br />
                    </p>
                  </div>
                  {/* End Single Feature */}
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  {/* Start Single Feature */}
                  <div
                    className="single-feature wow fadeInUp"
                    data-wow-delay=".6s"
                  >
                    <i className="lni lni-heart"></i>
                    <h3>Brilliant UX</h3>
                    <p>
                      Froly automates organising playdates for the little-ones
                      like magic
                    </p>
                  </div>
                  {/* End Single Feature */}
                </div>

                {/* End Single Feature */}
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  {/* Start Single Feature */}
                  <div
                    className="single-feature wow fadeInUp"
                    data-wow-delay=".2s"
                  >
                    <i className="lni lni-sun"></i>
                    <h3>Discover Camps</h3>
                    <p>
                      Find and book camps near you<br /><br />
                    </p>
                  </div>
                  {/* End Single Feature */}
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  {/* Start Single Feature */}
                  <div
                    className="single-feature wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <i className="lni lni-lock"></i>
                    <h3>GDPR Compliant</h3>
                    <p>
                      We don't ask for much personal information and its always <u>encrypted</u>.
                    </p>
                  </div>
                  {/* End Single Feature */}
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  {/* Start Single Feature */}
                  <div
                    className="single-feature wow fadeInUp"
                    data-wow-delay=".6s"
                  >
                    <i className="lni lni-chevron-down"></i>
                    <h3>Low Noise</h3>
                    <p>
                      Froly is not another attention seeking app.<br /><br />
                    </p>
                  </div>
                  {/* End Single Feature */}
                </div>

                {/* End Single Feature */}
              </div>
            </div>
          </section>

          {/* End Features Area */}
          <section className="our-achievement section">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 col-md-12 col-12">
                  <div className="title">
                    <h2>How it works</h2>
                    {/* <p>
                      For Week 4
                    </p> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 offset-lg-2 col-md-12 col-12">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-12">
                      <div
                        className="single-achievement wow fadeInUp"
                        data-wow-delay=".2s"
                      >
                        <h3 className="counter">
                          <span id="secondo1">1. Signup</span>
                        </h3>
                        {/* <p style={{'text-transform':'none'}}>Step 1<br/>Create your account</p> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                      <div
                        className="single-achievement wow fadeInUp"
                        data-wow-delay=".4s"
                      >
                        <h3 className="counter">
                          <span id="secondo2" className="countup" cup-end="120">
                            2. Add family
                          </span>
                        </h3>
                        {/* <p>Step 2<br/>Only Handles</p> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                      <div
                        className="single-achievement wow fadeInUp"
                        data-wow-delay=".6s"
                      >
                        <h3 className="counter">
                          <span id="secondo3" className="countup" cup-end="125">
                            3. Share
                          </span>
                        </h3>
                        {/* <p>Step 3 <br/>Share calendar with your friends</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* { NOW START OF PRICING SECTION} */}

          <section id="pricing" className="pricing-table section">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="section-title">
                    <h2>Why</h2>
                  </div>
                  <p className="hero-content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin consectetur a mauris eget mattis. Sed lectus nunc, malesuada nec eleifend at, congue eget nunc. Vivamus ac turpis metus. Morbi elementum elit sit amet dui eleifend lobortis. Nullam at posuere lorem. Praesent sagittis ligula vel sollicitudin imperdiet. Nulla ac iaculis tellus. Vivamus vestibulum velit eget tincidunt rhoncus. Cras et iaculis orci. Vestibulum et purus et dui pellentesque aliquam.
                  </p><br /><p className="hero-content">
                    Sed eget nisl quis mi malesuada tempus. Nam lacinia ullamcorper quam, id dignissim erat sollicitudin sit amet. Sed auctor neque ut molestie lacinia. Etiam commodo, elit non auctor fermentum, ipsum magna pretium urna, ac ornare est lectus eu elit. Vivamus dignissim arcu sit amet enim vulputate, sit amet tempor nisl sagittis. Donec accumsan ipsum vitae libero rutrum scelerisque. Quisque nec justo quis ante sollicitudin porta nec vitae lectus. Donec facilisis leo non tristique congue. Integer nec hendrerit nisl. Nunc sagittis nibh in varius dictum. Morbi porta eros sit amet est accumsan, tempor lobortis turpis vulputate. Pellentesque eget blandit lorem. Morbi hendrerit scelerisque faucibus. Nulla gravida justo quis lacus aliquet, a imperdiet mi blandit. Nullam vestibulum tristique dictum.


                    <br /><br />
                    MP
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Start Footer Area */}
          <footer className="footer">
            {/* Start Footer Top */}
            <div className="footer-top">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Widget */}
                    <div className="single-footer f-about">
                      <div className="logo">
                        <a href="/">
                          <img src={logo} alt="Logo" />
                        </a>
                      </div>
                      <p>tagline</p>
                      <ul className="social">
                        <li>
                          <a href="javascript:void(0)">
                            <i className="lni lni-facebook-filled"></i>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <i className="lni lni-twitter-original"></i>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <i className="lni lni-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* End Single Widget */}
                  </div>
                  <div className="col-lg-8 col-md-8 col-12">
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-12">
                        {/* Single Widget */}
                        <div className="single-footer f-link">
                          <h3>Why</h3>
                        </div>
                        {/* End Single Widget */}
                      </div>
                      <div className="col-lg-3 col-md-6 col-12">
                        {/* Single Widget */}
                        <div className="single-footer f-link">
                          <h3>Blog</h3>
                        </div>
                        {/* End Single Widget */}
                      </div>
                      <div className="col-lg-3 col-md-6 col-12">
                        {/* Single Widget */}
                        <div className="single-footer f-link">
                          <h3>Privacy</h3>
                        </div>
                        {/* End Single Widget */}
                      </div>
                      <div className="col-lg-3 col-md-6 col-12">
                        {/* Single Widget */}
                        <div className="single-footer f-link">
                          <h3>Contact</h3>
                        </div>
                        {/* End Single Widget */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Footer Top */}
          </footer>
          {/* End Footer Area */}
        </div>
      )}
    </>
  );
}

export default App;
