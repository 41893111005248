import React, { useState } from "react";
import "./dashboard.css";
import moment from "moment";
import MobileCalendar from "react-scroll-calendar";
import "react-scroll-calendar/build/react-scroll-calendar.css";
import DateForm from "./DateForm";

const Dashboard = () => {
  const [selectedFriend, setSelectedFriend] = useState(null);
  const [showFriends, setShowFriends] = useState(true);
  const [newFriendName, setNewFriendName] = useState("");
  const [friends, setFriends] = useState([
    { id: 1, name: "John Doe" },
    { id: 2, name: "Jane Smith" },
    { id: 3, name: "Mike Johnson" },
  ]);
  const [messages, setMessages] = useState({});
  const [messageText, setMessageText] = useState("");
  const [isMobilePanelVisible, setIsMobilePanelVisible] = useState(true); // Mobile panel visibility state
  const [formDetails, setFormDetails] = useState(null);
  const [friendDates, setFriendDates] = useState({});

  const minDate = moment().startOf("month");
  const maxDate = moment().add(1, "year").startOf("day");

  const toggleFriendsList = () => {
    setShowFriends(!showFriends);
  };

  const handleFriendClick = (friend) => {
    setSelectedFriend(friend);
    setFormDetails(null);
    setIsMobilePanelVisible(false); // Close panel on mobile after selecting a friend
  };

  const handleAddFriend = (name) => {
    if (name.trim()) {
      const newFriend = { id: friends.length + 1, name };
      setFriends([...friends, newFriend]);
      setNewFriendName("");
      setFriendDates({ ...friendDates, [newFriend.id]: moment() }); // Set default date for new friend
    }
  };

  const handleSendMessage = () => {
    if (messageText.trim() && selectedFriend) {
      setMessages((prevMessages) => ({
        ...prevMessages,
        [selectedFriend.id]: [
          ...(prevMessages[selectedFriend.id] || []),
          { text: messageText, user: "You" },
        ],
      }));
      setMessageText("");
    }
  };

  const handleDateSelect = (date) => {
    if (selectedFriend) {
      setFriendDates((prevDates) => ({
        ...prevDates,
        [selectedFriend.id]: date,
      }));
      setFormDetails({ friendName: selectedFriend.name, selectedDate: date });
    }
  };

  const handleMobileNavbarClick = () => {
    setIsMobilePanelVisible(!isMobilePanelVisible);
  };

  return (
    <div className="dashboard">
      <div
        className={`friend-list-panel ${
          isMobilePanelVisible ? "mobile-visible" : ""
        }`}
      >
        <div className="navbar">
          <div className="navbar-left">
            <span className="navbar-title">PlayData</span>
            <span className="down-arrow" onClick={toggleFriendsList}>
              ▼
            </span>
          </div>
          <div className="navbar-right">
            <span className="write-notes-icon">📝</span>
          </div>
        </div>
        {showFriends && (
          <>
            <div className="direct-messages">Direct Messages</div>
            <ul className="friend-list">
              {friends.map((friend) => (
                <li
                  key={friend.id}
                  onClick={() => handleFriendClick(friend)}
                  className="friend-item"
                >
                  {friend.name}
                </li>
              ))}
            </ul>
            <div className="add-friend">
              <input
                type="text"
                placeholder="Add Friend"
                value={newFriendName}
                onChange={(e) => setNewFriendName(e.target.value)}
                className="add-friend-input"
              />
              <button
                className="add-friend-btn"
                onClick={() => handleAddFriend(newFriendName)}
              >
                +
              </button>
            </div>
          </>
        )}
      </div>

      <div className="mobile-navbar" onClick={handleMobileNavbarClick}>
        <div className="navbar-left">
          <span className="navbar-title">PlayData</span>
          <span className="down-arrow">▼</span>
        </div>
        <div className="navbar-right">
          <span className="write-notes-icon">📝</span>
        </div>
      </div>
      {formDetails &&
      selectedFriend.id ===
        friends.find((f) => f.name === formDetails.friendName).id ? (
        <DateForm
          friendName={formDetails.friendName}
          selectedDate={formDetails.selectedDate}
        />
      ) : (
        <div className="chatbox-container">
          {selectedFriend ? (
            <div className="chatbox">
              <div className="chatbox-header">
                <span>{selectedFriend.name}'s Chat</span>
              </div>
              <div className="chatbox-body">
                {messages[selectedFriend.id] &&
                messages[selectedFriend.id].length === 0 ? (
                  <div className="chatbox-placeholder">
                    <p>No messages yet</p>
                  </div>
                ) : (
                  messages[selectedFriend.id] &&
                  messages[selectedFriend.id].map((message, index) => (
                    <div key={index} className="chat-message">
                      <span className="chat-message-user">{message.user}:</span>
                      <span>{message.text}</span>
                    </div>
                  ))
                )}

                <div className="infinite-calendar">
                  <MobileCalendar
                    minDate={minDate}
                    selectedDate={friendDates[selectedFriend.id] || null}
                    maxDate={maxDate}
                    onSelect={handleDateSelect}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="chatbox chatbox-placeholder">
              <p>Select a friend to start chatting</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
