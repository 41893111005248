import React from "react";
import "./dateform.css";

const DateForm = ({ friendName, selectedDate }) => {
  return (
    <div className="date-form-container">
      <div className="date-form-navbar">
        <button className="date-form-nav-button">❮ Cancel</button>
        <span className="date-form-nav-title">New Date</span>
        <button className="date-form-nav-button">+ Add</button>
      </div>
      <div className="date-form">
        <h3>{friendName}'s Date</h3>
        <p>Date: {selectedDate.format("YYYY-MM-DD")}</p>
        <div className="form-field">
          <span className="form-label">Time:</span>
          <input type="time" className="form-input" />
        </div>
        <div className="form-field">
          <span className="form-label">{friendName}</span>
        </div>
        <button className="add-button">Save</button>
      </div>
    </div>
  );
};

export default DateForm;
