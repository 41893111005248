import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SignupPage.css"; // Import the updated CSS

const SignupPage = () => {
  const [step, setStep] = useState(1); // State to manage the current step
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]); // OTP fields
  const [otpVerified, setOtpVerified] = useState(false); // State to manage OTP verification status
  const [otpMessage, setOtpMessage] = useState(""); // State to manage OTP message display
  const navigate = useNavigate(); // Hook for navigation

  const handleVerifyOtp = () => {
    const otpString = otp.join("");
    if (otpString === "1234") {
      // Save data to localStorage
      const userData = { name, phone };
      localStorage.setItem("user", JSON.stringify(userData));
      alert("OTP Verified Successfully");
      setOtpVerified(true); // Set OTP verification status
    } else {
      setOtpMessage("Invalid OTP. Please try again."); // Set error message
      setOtpVerified(false);
    }
  };

  const handleNext = () => {
    if (step === 1 && name.trim() !== "") {
      setStep(2);
    } else if (step === 2 && phone.trim() !== "") {
      setStep(3);
    } else if (step === 3 && otpVerified) {
      navigate("/dashboard"); // Redirect to /dashboard if OTP is verified
    }
  };

  const handleOtpChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value.slice(0, 1); // Limit input to one digit
    setOtp(newOtp);

    // Move cursor to next field
    if (e.target.value.length > 0 && index < otp.length - 1) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  return (
    <div className="overlay">
      <div
        className={`signup-box ${
          step === 1 ? "step-1" : step === 2 ? "step-2" : "step-3"
        }`}
      >
        {step === 1 && (
          <>
            <h2>Join Froly!</h2>
            <div className="form-group">
              <label htmlFor="name">1. Your Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your name"
              />
            </div>
            <button className="next-btn" onClick={handleNext}>
              Next
            </button>
          </>
        )}

        {step === 2 && (
          <>
            <h2>Join Froly!</h2>
            <div className="form-group">
              <label htmlFor="phone">2. Your Mobile</label>
              <input
                type="text"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter your phone number"
              />
            </div>
            <button className="next-btn" onClick={handleNext}>
              Next
            </button>
          </>
        )}

        {step === 3 && (
          <>
            <h2>Join Froly!</h2>
            <div className="otp-group">
              <label htmlFor="otp">3. Enter Codde</label>
              <div className="otp-inputs">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    id={`otp-${index}`}
                    value={digit}
                    onChange={(e) => handleOtpChange(e, index)}
                    maxLength="1"
                    pattern="[0-9]"
                    placeholder="-"
                  />
                ))}
              </div>
              {otpMessage && <p className={`otp-message ${otpVerified ? 'success' : 'error'}`}>{otpMessage}</p>}
              <button className="resend-btn">Resend OTP</button>
              <button className="verify-btn" onClick={handleVerifyOtp}>
                Verify OTP
              </button>
              {/* <button
                className="next-btn"
                onClick={handleNext}
                disabled={!otpVerified}
              >
                Next
              </button> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SignupPage;
