import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ element: Component }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();

  // Check if user is authenticated
  const isAuthenticated = user && user.phone;

  return isAuthenticated ? (
    Component
  ) : (
    <Navigate to="/" state={{ from: location }} />
  );
};

export default ProtectedRoute;
